.nutritionTodayWrap {
    align-items: center;
}
.nutritionTodayValueWrap {
    margin-bottom: 16px;
}
.nutritionTodayValueWrap > p {
    font-size: 1.2rem;
    padding-bottom: 0.2rem;
}
.nutritionTodayValueWrap > p:nth-child(2) {
    font-weight: bold;
}
.proteinTodayValueLabel {
    color: #35C759;
}
.fatsTodayValueLabel {
    color: #2893FF;
}
.carbohydratesTodayValueLabel {
    color: #c29a00;
}
.caloriesTodayValueLabel {
    color: #D20000;
}
@media (prefers-color-scheme: dark) {
    .carbohydratesTodayValueLabel {
        color: #FFCC02;
    }
}