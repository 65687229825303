.workoutRow {
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 13px 6px rgba(0,0,0,0.05);
}
.workoutRowSeparator {
    margin: 25px 20px;
}
.workoutTypeLabel {
    font-weight: 700;
    margin-bottom: -1px;
}
.workoutDateLabel {
    margin-bottom: 0;
}
#workoutsList {
    margin-bottom: 15px;
}
.workoutsDatePicker {
    margin-top: 0;
    background-color: transparent;
    color: #0f0f0f;
}
@media (max-width: 1199px) {
    .workoutChartWrapper {
        margin-top: 20px;
    }
}
.workoutTotalMetaInfosDivs > * {
    float: left;
    margin-right: 30px;
}
.workoutMetaInfosDivs {
    display: grid;
    grid-template-columns: repeat(auto-fill, 225px);
    grid-auto-rows: min-content;
    min-height: 0;
}
.workoutMetaInfosDivs * {
    float: left;
}
.workoutMetaInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    padding-left: 0 !important;
}
.workoutMetaInfo p {
    margin-bottom: 0;
}
.workoutMetaInfo div {
    display: flex;
    flex-direction: column;
}
.workoutMetaInfoLabel {
    margin-bottom: -5px;
}
.workoutMetaInfo img {
    margin-right: 10px;
    width: 26px;
    height: 26px;
}
.workoutMetaValueLabel {
    margin-top: -5px;
    font-weight: 700;
    margin-bottom: 0;
}
.workoutFiltrationRow {
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
.workoutsExportButton {
    margin-left: 15px;
    margin-right: 30px;
}
@media (max-width: 660px) {
    .workoutFiltrationRow {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
    .workoutsExportButton {
        margin-left: 0;
        margin-right: 0;
    }
    .workoutFiltrationRow > *, #workoutTypePeriodDropdown {
        width: 100%;
    }
}
@media (max-width: 500px) {
    .workoutFiltrationRow {
        padding-left: 24px;
        padding-right: 24px;
    }
}
.workoutFiltrationRow p {
    margin: 0 -20px 0 0;
}
@media (max-width: 991px) {
    .workoutFiltrationRow p {
        margin: 0;
    }
}

@media (prefers-color-scheme: dark) {
    .workoutsDatePicker {
        color: #fafafa;
    }
}