.loginBody {
  font-family: 'Roboto',serif;
  background-color: #43a047;
  margin: 0;
  color: black;
  width: 100%;
  height: 100%;
  position: fixed;
}

.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  height: 100%;
}

.loginWrapDiv {
  width: 550px;
  background-color: white;
  border-radius: 15px;
  padding: 30px;
}

@media (max-width: 640px) {
  .loginWrapDiv {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    padding: 0;
  }
}

.loginWrapDiv h1 {
  margin-top: 0;
}

@media (max-width: 640px) {
  .loginWrapDiv h1 {
    color: white;
    background-color: #43a047;
    padding: 15px;
    font-size: 28px;
  }
}

@media (max-width: 640px) {
  .loginWrapDiv p {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.loginWrapDiv a {
  color: #43a047;
}

#appleid-signin {
  margin-top: 60px !important;
  display: table;
  margin: 0 auto;
}

#appleid-signin:hover {
  cursor: pointer;
}

.loginSitePreview {
  display: block;
  margin: 70px auto 40px auto;
  max-height: 270px;
}

@media (max-width: 640px) {
  .loginSitePreview {
    width: 80%;
  }
}

.loginLegalInfo {
  font-size: 13px;
  opacity: 0.7 !important;
  text-align: center;
  margin-top: 30px;
}

.loginBody a {
  text-decoration: underline !important;
}