.defaultDashboardDatePresetWrapper {
    max-width: 300px;
    min-width: 300px;
}

@media (max-width: 1024px) {
    .defaultDashboardDatePresetWrapper {
        max-width: 100%;
        min-width: 100%;
    }
}