.sleepChartsSeparator {
    margin-top: 60px;
    margin-bottom: 60px;
}

.sleepDay {
    margin-top: 40px;
    margin-bottom: 40px;
}

.sleepChartsSeparator {
    margin-left: 15px;
}

.sleepDay h3 {
    margin-top: 0;
    font-weight: 400;
}

.sleepDayDataWrapper {
    display: flex;
}

.sleepChartWrapper {
    flex: 1;
    min-width: 0;
}

.sleepValuesWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100px;
}

.sleepDayDataWrapperMobile {
    display: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sleepValuesWrapper p {
    margin: 0;
    font-weight: 400;
}

.timeAsleepValueLabel {
    margin-top: 9px !important;
}

.sleepMobileValueNameLabel {
    font-weight: 400;
    margin-bottom: 0;
}

.sleepMobileValueLabel {
    margin-top: 0;
    font-weight: 600;
}