.heCard {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 13px 6px rgba(0,0,0,0.05);
}
.heCardTopMargin {
    margin-top: 15px;
}
.heCardHorizontalMargin {
    margin-left: 15px;
    margin-right: 15px;
}
@media (max-width: 660px) {
    .heCard {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}