.gaugeChart {
	display: grid;
}
.gaugeChart > div {
	grid-row: 1;
	grid-column: 1;
}
.gaugeWrapper {
	width: 100%;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	grid-template-columns: 1fr;
}
.gaugeWrapper svg {
	width: 100%;
	grid-area: 1 / 1 / 2 / 2;
}
.gauseTitleWrapper {
	width: 100%;
	display: grid;
}
.gaugeSvgClip {
	display: grid;
	width: 100%;
	overflow: hidden;
	grid-area: 1 / 1 / 5 / 2;
}
.gaugeSvgClip svg {
	width: 100%;
	grid-area: 1 / 1 / 2 / 2;
}
.gauseTitleWrapper {
	grid-area: 4 / 1 / 5 / 5;
}
.gauseTitleWrapper .titleCenterBox {
	width: 100%;
	z-index: 3;
	margin-bottom: 0;
	display: flex;
	justify-content: center;
    align-items: center;
	background-color: white;
}
@media (prefers-color-scheme: dark) {
	.gauseTitleWrapper .titleCenterBox {
		background-color: #171717;
	}	
}

.gauseTitleWrapper .titleCenterBox p {
	margin: 0;
	font-size: 2rem;
    font-weight: bold;
}
.gaugeAspectRatioFixer svg {
	display: grid;
}
.gaugeAspectRatioFixer svg {
	grid-area: 1 / 1 / 5 / 5;
}