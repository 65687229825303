.nutritionSectionHeader {
    font-weight: bold;
}
.nutritionFullWidthDiv {
    width: 100%;
}
.nutritionFullWidthDiv > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}