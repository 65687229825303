.roundedRectWrapper {
    margin: 15px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 13px 6px rgb(0 0 0 / 5%);

}
@media (prefers-color-scheme: dark) {
    .roundedRectWrapper {
        background-color: #171717;
    }
}