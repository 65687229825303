.componentEditTopRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.componentEditTopRow p {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 1.25em;
}

.componentTypeWrap {
    display: flex;
    align-items: center;
}

.componentTypeWrap a {
    color: rgba(0,0,0,0.5);
    margin-left: 8px;
}

@media (prefers-color-scheme: dark) {
    .componentTypeWrap a {
        color: rgba(255,255,255,0.5);
    }
}

.topPickersWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
}

.topPickersWrapper * p {
    margin-top: 0;
    margin-bottom: 5px;
}

.typeSelectWrapper {
    max-width: 500px;
    min-width: 300px;
}

.timePeriodWrapperSelectWrapper, .workoutsMetadataSelectWrapper {
    max-width: 500px;
    min-width: 300px;
}

@media (max-width: 1024px) {
    .topPickersWrapper {
        flex-direction: column;
    }
    .typeSelectWrapper {
        max-width: 100%;
        min-width: 100%;
    }
    .timePeriodWrapperSelectWrapper, .workoutsMetadataSelectWrapper {
        max-width: 100%;
        min-width: 100%;
        margin-left: 0;
        margin-top: 8px;
    }
}