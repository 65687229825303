.totalValueLabel {
    width: 100%;
    text-align: center;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
}