.nutritionHistoryEmptyState {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nutritionHistoryEmptyState p {
    opacity: 0.8;
    text-align: center;
    margin: 0 !important;
}