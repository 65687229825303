.dashboardSettingsItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dashboardSettingsItem h3 {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
}
.dashboardSettingsItemActionsWrapper {
    display: flex;
}
.dashboardSettingsItemActionsWrapper button {
    margin-left: 12px;
}
@media (max-width: 660px) {
    .dashboardSettingsItemWrapper {
        margin-left: 10px;
        margin-right: 10px;
    }
    .dashboardSettingsItem {
        align-items: flex-start;
        flex-direction: column;
    }
    .dashboardSettingsItemActionsWrapper button {
        margin-left: 0;
        margin-right: 12px;
    }
    .dashboardSettingsItem h3 {
        margin-bottom: 8px;
    }
}