.dashboardHeader {
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
}
@media (max-width: 660px) {
    .dashboardHeader {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
    .workoutsExportButton {
        margin-left: 0;
        margin-right: 0;
    }
    .dashboardHeader > *, #workoutTypePeriodDropdown, .dashboardsDropdown {
        width: 100%;
    }
}
@media (max-width: 500px) {
    .dashboardHeader {
        padding-left: 24px;
        padding-right: 24px;
    }
}
.dashboardHeader p {
    margin: 0 -20px 0 0;
}
@media (max-width: 991px) {
    .dashboardHeader p {
        margin: 0;
    }
}
.dashboardsDropdown {
    margin-right: 15px;
}