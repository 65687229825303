.csvSearchWrapper {
    display: flex;
}
.csvSearchWrapper > label {
    margin-right: 16px;
    font-weight: 300;
    color: #3c4858;
}
@media (prefers-color-scheme: dark) {
    .csvSearchWrapper > label {
        color: white;
    }
}
.csvSearchWrapper > input {
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #a0a0a0 !important;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
}
.csvSearchForm {
    margin-bottom: 0;
}