.cartTitleWrapper {
    display: flex;
    justify-content: space-between;
}
.cartTitleWrapper p {
    opacity: 0.8;
    margin: 0;
}
.cardTitleRightSide {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
}
.cardTitleRightSide * {
    height: 100%;
}
.cardTitleRightSide img {
    margin-right: 10px;
    opacity: 0.4;
    height: 15px;
    vertical-align: middle;
    display: inline-block;
}
.cardTitleRightSide img:hover {
    cursor: pointer;
}