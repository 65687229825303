.loaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderWrapperDefaultHeight {
    min-height: calc(100vh - 123px);
}
@media (max-width: 1024px) {
    .loaderWrapperDefaultHeight {
        min-height: calc(100vh - 275px);
    }
}
.loader {
    border: 8px solid rgba(255,255,255,.1);
    border-top: 8px solid #66bb6a;
}
.loader {
    border: 8px solid rgba(0,0,0,.1);
    border-top: 8px solid #66bb6a;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.25s linear infinite;
}