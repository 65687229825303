.stackedBarLineChartConfiguration_secondaryTypeWrapper {
    display: flex;
    align-items: center;
}

.stackedBarLineChartConfiguration_secondaryTypeWrapper p {
    margin: 0;
}

.stackedBarLineChartConfiguration_secondaryTypeWrapper a {
    color: rgba(0,0,0,0.5);
    margin-left: 8px;
}

.stackedTypesInfo {
    opacity: 0.75;
    margin-top: 16px;
    margin-bottom: 16px;
}